@font-face {
  font-family: "BasierCircle-Regular";
  src: url("/src/assets/fonts/BasierCircle-Regular.woff2") format("woff2"),
    url("/src/assets/fonts/BasierCircle-Regular.woff") format("woff");
  font-synthesis-weight: auto;
}
@font-face {
  font-family: "BasierCircle-Medium";
  src: url("/src/assets/fonts/BasierCircle-Medium.woff2") format("woff2"),
    url("/src/assets/fonts/BasierCircle-Medium.woff") format("woff");
  font-synthesis-weight: auto;
}
@font-face {
  font-family: "BasierCircle-SemiBold";
  src: url("/src/assets/fonts/BasierCircle-SemiBold.woff2") format("woff2"),
    url("/src/assets/fonts/BasierCircle-SemiBold.woff") format("woff");
  font-synthesis-weight: auto;
}
@font-face {
  font-family: "BasierCircle-Bold";
  src: url("/src/assets/fonts/BasierCircle-Bold.woff2") format("woff2"),
    url("/src/assets/fonts/BasierCircle-Bold.woff") format("woff");
  font-synthesis-weight: auto;
}

@font-face {
  font-family: "GPPolySans-Median";
  src: url("/src/assets/fonts/GPPolySans-Median.woff2") format("woff2"),
    ("/src/assets/fonts/GPPolySans-Median.woff") format("woff");
  font-synthesis-weight: auto;
}

@font-face {
  font-family: "GPPolySans-Slim";
  src: url("/src/assets/fonts/GPPolySans-Slim.woff2") format("woff2"),
    url("/src/assets/fonts/GPPolySans-Slim.woff") format("woff");
  font-synthesis-weight: auto;
}

@font-face {
  font-family: "GPPolySans-Neutral";
  src: url("/src/assets/fonts/PolySans-Neutral.woff2") format("woff2"),
    url("/src/assets/fonts/PolySans-Neutral.woff") format("woff");
  font-synthesis-weight: auto;
}

@font-face {
  font-family: "GPIcon-Regular";
  src: url("/src/assets/fonts/GPIcon-Regular.woff") format("woff");
  font-synthesis-weight: auto;
}

@font-face {
  font-family: "IBMPlexMono-Regular";
  src: url("/src/assets/fonts/IBMPlexMono-Regular.woff2") format("woff2"),
    url("/src/assets/fonts/IBMPlexMono-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  height: 100vh;
  margin: 0;
  font-family: "BasierCircle-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
